export default `
...on encounter {
non-repeat {
label
quizz {
...on quizz {
title
description
cta
body
}
}
video_title
video
}
repeat {
dialog_title
dialog_content
avatar
avatar_name
avatar_desc
}
}`