export const customTypeCollection = [
  'bar',
  'brand',
  'brand_category',
  'brand_homes',
  // 'business_center',
  'employee_experience',
  'learning_for_the_future',
  'modules',
  'on_trade',
  // 'pr_tv',
  'program',
  'project',
  'quizz',
  'spot',
  'test_evaluation',
  // 'tourist_office'
]

export const getDocumentCover = (options) => {
  const defaults = {
    customTypeCollection: [
      'brand',
      'brand_category',
      'brand_homes',
      // 'business_center',
      'employee_experience',
      'learning_for_the_future',
      'modules',
      'on_trade',
      // 'pr_tv',
      'program',
      'project',
      'spot',
      // 'tourist_office'
    ],
    fields: ['cover_image', 'cover_title']
  }

  options = { ...defaults, ...options }
  const { customTypeCollection, fields } = options

  let documents = ''
  customTypeCollection.forEach((ct) => {
    let qFields = ''
    fields.forEach((field) => {
      qFields += `
${field}`
    })
    documents += `
...on ${ct}{
${qFields}
}`
  })
  return documents
}
