import { render, staticRenderFns } from "./UITPLeaderboard.vue?vue&type=template&id=66e834d6&scoped=true&"
import script from "./UITPLeaderboard.vue?vue&type=script&lang=ts&"
export * from "./UITPLeaderboard.vue?vue&type=script&lang=ts&"
import style0 from "./UITPLeaderboard.vue?vue&type=style&index=0&id=66e834d6&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e834d6",
  null
  
)

export default component.exports