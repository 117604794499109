import cover from './fragments/cover'
import menuItems from './fragments/menu-items'

import {
  title,
  text,
  bulletList,
  downloadAssets,
  links,
  keyFigures,
  category,
  relatedContent,
  photoLegend,
  photo,
  videoLegend,
  video,
  carousel,
  contentVideo,
  funFact,
  funFactAlt,
  quizz
} from './fragments/slices'

const graphQuery = `
{
    brand_homes {
        title
        ${cover}
        ${menuItems}
        body {
            ${title}
            ${text}
            ${bulletList}
            ${downloadAssets}
            ${links}
            ${keyFigures}
            ${category}
            ${relatedContent}
            ${photoLegend}
            ${photo}
            ${videoLegend}
            ${video}
            ${carousel}
            ${contentVideo}
            ${funFact}
            ${funFactAlt}
            ${quizz}
        }
    }
}`

export default graphQuery
