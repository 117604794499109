var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab h3",class:[
    {
      inerte: _vm.inerte === true,
      active: _vm.active === true,
      grey: _vm.grey === true
    },
    _vm.schemeClass
  ],on:{"click":function($event){$event.preventDefault();return _vm.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"tab__inner"},[_c('div',{staticClass:"label"},[_c('span',{staticClass:"label__default"},[_vm._v(_vm._s(_vm.label))]),_c('span',{staticClass:"label__active",attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.label))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }