import { render, staticRenderFns } from "./UIBtn.vue?vue&type=template&id=19c35d71&scoped=true&"
import script from "./UIBtn.vue?vue&type=script&lang=ts&"
export * from "./UIBtn.vue?vue&type=script&lang=ts&"
import style0 from "./UIBtn.vue?vue&type=style&index=0&id=19c35d71&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c35d71",
  null
  
)

export default component.exports