import { render, staticRenderFns } from "./UIRelatedContent.vue?vue&type=template&id=7bb1e9fb&scoped=true&"
import script from "./UIRelatedContent.vue?vue&type=script&lang=ts&"
export * from "./UIRelatedContent.vue?vue&type=script&lang=ts&"
import style0 from "./UIRelatedContent.vue?vue&type=style&index=0&id=7bb1e9fb&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb1e9fb",
  null
  
)

export default component.exports