<template>
  <div class="ab-section-selector">

    <div class="content">
      <div class="c-labels-wrapper" :style="labelsTranslate">
        <div
          v-for="(section, index) in sections"
          class="c-label-item"
          :key="section.id"
        >
          <div
            class="c-label title-h2"
            :class="{ 
              active: current == index ,
              hover: (hoverprev && current == index+1) || (hovernext && current == index-1)
            }"
            @click="setCurrent(index)"
          >
            {{ getSectionLabel(section) }}
          </div>
        </div>
      </div>
    </div>
       
    <div
      class="arrowbtn prev rounded-8 md:rounded elevation-12"
      @click="setCurrent(current - 1)"
      @mouseenter="hoverprev=true"
      @mouseleave="hoverprev=false"
      :class="{ hidden: isFirstSelected }"
    >
      <icon class="icon" name="arrow-left" />
    </div>
    <div
      class="arrowbtn next rounded-8 md:rounded elevation-12"
      @click="setCurrent(current + 1)"
      @mouseenter="hovernext=true"
      @mouseleave="hovernext=false"
      :class="{ hidden: isLastSelected }"
    >
      <icon class="icon" name="arrow-right" />
    </div>
   

  </div>
</template>





<script>

export default {

  model: {
    prop: "current",
    event: "change",
  },

  props: {

    current: {
      type: Number,
      default: 0,
    },

    sections: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      hoverprev:false,
      hovernext:false,
    }
  },    

  computed: {
    isFirstSelected() {
      return this.current == 0;
    },

    isLastSelected() {
      return this.current == this.sections.length - 1;
    },

    labelsTranslate() {
      return `transform:translateX(${(-100 ) * (this.current)}%)`;
    },
  },

  methods: {
    setCurrent(val) {
      if (val > -1 && val < this.sections.length) {
        this.$emit("change", val);
      }
    },

    getSectionLabel(section){
      return this.$t(`avatar.step-${section.id}`)
    }

  },
};
</script>





<style lang="stylus" scoped>


.ab-section-selector {
  --height: rem(72);
  background: var( --primary-light);
  border-radius: rem(16);
  height: var(--height);
  display: flex;
  font-size: rem(18);
  padding rem(8)

  +mobile() {
    --height: rem(56);
    border-radius: rem(13);
  }

  .content {
    order: 0;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;

    .c-labels-wrapper {
      transition: transform 0.4s;
      height: 100%;
    }

    .c-label-item {
      height: 100%;
      line-height: 'calc(var(--height) - 2 * %s)' % rem(8);
      text-align: center;
      width: 100%;
      display: inline-block;

      .c-label {
        line-height: inherit
        cursor: pointer;
        transition: transform 0.4s, color 0.4s;
        display: inline-block;

        &.hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .arrowbtn {
    background white
    cursor: pointer;
    flex: 0 0 rem(56);
    display: flex;
    align-items: center;
    justify-content: center;
    transition transform .2s, opacity .3s

    +mobile() {
      flex-basis: rem(40);
    }

    &.hidden {
      cursor: default;
      opacity 0
    }
    
    &:hover {
      transform scale(1.01)
    }

    &:before {
      content:''
      // background red
      width rem(140)
      height rem(48)
      position absolute
    }

    .icon {
      width 1em
      height 1em
      font-size rem(32)

      +mobile() {
        font-size rem(22)
      }
    }
  }

  .prev {
    order: -1;
    
    &:before {
      left rem(8)
    }

    // .arrow {
    //   transform: rotate(180deg);
    // }
  }

  .next {
    order: 1;    
    &:before {
      right rem(8)
    }
  }


  // .arrow {
  //   background-image: url('~@/assets/tmp/small-arrow.svg');
  //   background-size: cover
  //   align-items: center
  //   display: inline-flex
  //   font-size: rem(24)
  //   justify-content: center
  //   vertical-align: middle
  //   width: rem(16)
  //   height: rem(16)
  // }
}
</style>