import cover from './fragments/cover'
import menuItems from './fragments/menu-items'
import projecID from './fragments/project-id'
import next from './fragments/next'

import {
  title,
  text,
  bulletList,
  downloadAssets,
  links,
  keyFigures,
  category,
  relatedContent,
  photoLegend,
  photo,
  videoLegend,
  video,
  carousel,
  contentVideo,
  funFact,
  funFactAlt,
  quizz,
  test,
  projectID as projectIDSlice,
  projectCard,
  expert
} from './fragments/slices'

const graphQuery = `
{
modules {
title
drink_description
drink_image
related_bars {
link
}
${cover}
${next}
${menuItems}
${projecID}
body {
${title}
${text}
${bulletList}
${downloadAssets}
${links}
${keyFigures}
${category}
${relatedContent}
${photoLegend}
${photo}
${videoLegend}
${video}
${carousel}
${contentVideo}
${funFact}
${funFactAlt}
${quizz}
${test}
${projectIDSlice}
${projectCard}
${expert}
}
}
}`

export default graphQuery