<template>
  <div class="swatch">
    <div class="color-list">
      <div
        class="color-item"
        v-for="color in select.options"
        v-bind:key="color.id"
        :class="{
          selected: color.id == currentConfig[select.id],
        }"
        @click="currentConfig[select.id] = color.id"
      >
        <div class="dot" :style="{ background: color.val }" />
        <div class="border" />
      </div>
    </div>
  </div>
</template>




<script>
export default {
  props: {
    select: {
      type: Object,
      required: true,
    },

    currentConfig: {
        type:Object,
        required: true,
    },

  },
};
</script>




<style lang="stylus" scoped>
.swatch {
  position relative
  width 100%
  overflow hidden
  order 2

  +mobile() {
    margin-left rem(16)

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: rem(46);
      pointer-events: none;
      background: linear-gradient(270deg, rgba(255, 255, 255, 1) 19.57%, rgba(255, 255, 255, 0) 95.65%);
    }
  }
  +above('md') {
    margin-top rem(10)
    padding 0 rem(24 - 12)
  }
}

.color-list {
  display flex

  +mobile() {
    overflow-x auto
    margin-left rem(-6)

    &::-webkit-scrollbar {
      display none
    }
  }
  +above('md') {
    flex-wrap wrap
    justify-content center
  }
}

.color-item {
  cursor pointer;
  padding: rem(12);
  width rem(22) + 2 * @padding;
  height @width;
  position relative;

  &:last-child {
    +mobile() {
      margin-right rem(46)
    }
  }

  .dot {
    width: rem(22)
    height: rem(22)
    border-radius: 100%;
  }

  .border {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: rem(34);
    height: rem(34);
    margin: auto;
    border solid rem(3) var(--primary-blue) 
    border-radius: 100%;
    transition: transform 0.2s, opacity 0.1s;
    transform: scale(0.5);
    opacity: 0;
  }

  &.selected {
    .border {
      opacity: 1;
      transform: scale(1);
    }
  }

  &:not(.selected):hover {
    .border {
      opacity: 0.5;
      transform: scale(0.8);
    }
  }
}
</style>