export default `
...on test {
non-repeat {
title
description
cta
test {
...on test_evaluation {
title
description
cta
a_type_title
a_type_subtitle
a_type_desc_0
a_type_desc_1
k_type_title
k_type_subtitle
k_type_desc_0
k_type_desc_1
v_type_title
v_type_subtitle
v_type_desc_0
v_type_desc_1
ak_type_title
ak_type_subtitle
ak_type_desc_0
ak_type_desc_1
av_type_title
av_type_subtitle
av_type_desc_0
av_type_desc_1
kv_type_title
kv_type_subtitle
kv_type_desc_0
kv_type_desc_1
body
}
}
}
}`