var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"validator",attrs:{"rules":_vm.rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.failed;
var validate = ref.validate;
return [_c('div',{staticClass:"ui-radio-group",class:[{ 'has-error': error }, _vm.schemeClass]},[_c('label',{staticClass:"label title-h3"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"input"},[(_vm.grid)?_c('div',{staticClass:"grid rounded",class:[_vm.schemeClass]},_vm._l((_vm.options),function(option){return _c('div',{key:option.id,staticClass:"input-grid-option",class:[{ checked: option.id === _vm.checked }]},[_c('UIGridBtn',{class:[_vm.schemeClass],attrs:{"picto":option.picto,"text":option.name,"disabled":option.id === _vm.checked},on:{"click":function () {
                validate(option.id)
                _vm.checkedValue !== option.id ? (_vm.checkedValue = option.id) : ''
              }}})],1)}),0):_vm._l((_vm.options),function(option){return _c('btn',{key:option.id,staticClass:"input-option",class:[{ checked: option.id === _vm.checked }],attrs:{"large":"","wide":"","radio":"","active":option.id === _vm.checked,"inheritColor":""},on:{"click":function () {
            validate(option.id)
            _vm.checkedValue !== option.id ? (_vm.checkedValue = option.id) : ''
          }}},[_c('div',{staticClass:"input-name cta-small uppercase",class:{ 'is-checked': option.id === _vm.checked }},[_c('span',{staticClass:"unchecked"},[_vm._v(_vm._s(option.name))]),_c('span',{staticClass:"checked cta-small-bold",attrs:{"aria-hidden":""}},[_vm._v(_vm._s(option.name))])])])})],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }