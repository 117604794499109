var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uiprogress-sand-r"},[_c('transition-group',{staticClass:"list-transition",attrs:{"name":"list","tag":"div"}},_vm._l((_vm.ui),function(item){return _c('div',{key:item,staticClass:"list-item",class:("is-" + item)},[(item === _vm.UI.progress)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"top title-h3"},[_vm._v(" "+_vm._s(_vm.$t('s-and-r.progress-title'))+" ")]),_c('div',{staticClass:"list rounded"},_vm._l((_vm.spotsWithStatus),function(spot,i){return _c('UIQuizzIcon',{key:i,staticClass:"spot",attrs:{"type":spot.unlocked ? 'check' : 'bar-hidden-bar-lock'}})}),1)]):(item === _vm.UI.backpack)?_c('div',{staticClass:"backpack rounded-xl"},_vm._l((_vm.backpack),function(ref){
var id = ref.id;
var picto = ref.picto;
var disabled = ref.disabled;
return _c('div',{key:id,staticClass:"tool rounded",class:{
            'is-disabled': disabled && _vm.currentSpotTool !== id,
            'is-draggable': _vm.canDrag
          },on:{"mousedown":function($event){$event.preventDefault();return _vm.onMouseDown($event, id)},"touchstart":function($event){$event.preventDefault();return _vm.onMouseDown($event, id)},"touchend":function($event){$event.preventDefault();return _vm.onTouchEnd($event, id)}}},[_c('picto',{attrs:{"name":picto}})],1)}),0):_vm._e()])}),0),_c('UIPortal',[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"drag-container"},[_c('transition',{attrs:{"name":"drag-pop","mode":"out-in"}},[(_vm.draggedObject)?_c('div',{key:_vm.draggedObject,staticClass:"dragged-object",style:({ top: (_vm.dragTop + "px"), left: (_vm.dragLeft + "px") })},[_c('div',{staticClass:"dragged-object__inner rounded elevation-16"},[_c('picto',{staticClass:"picto",attrs:{"name":_vm.draggedObject}})],1)]):_vm._e()])],1)])],1),_c('div',{ref:"empty",staticClass:"empty"},[_vm._v(" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }