var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-pagination",class:[
    {
      'on-white': _vm.onWhite,
      'is-readonly': _vm.readonly
    }
  ]},[(_vm.showNav)?_c('UIBtn',{staticClass:"nav",class:[{ 'is-disabled': _vm.isFirst }],attrs:{"primary":"","color":"accent","shadow":!_vm.isFirst,"icon":"arrow-left"},on:{"click":_vm.prev}}):_vm._e(),_c('div',{staticClass:"dots rounded-12"},_vm._l((_vm.pageCount),function(value,index){return _c('div',{key:index,staticClass:"dot",class:[
        {
          'is-active': index === _vm.currentValue
        }
      ],attrs:{"title":value},on:{"click":function($event){return _vm.setCurrentValue(index)}}})}),0),(_vm.showNav)?_c('UIBtn',{staticClass:"nav next",class:[{ 'is-disabled': _vm.isLast }],attrs:{"primary":"","color":"accent","shadow":!_vm.isLast,"icon":"arrow-right"},on:{"click":_vm.next}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }