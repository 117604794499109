import gallery from './fragments/gallery'
import { event, videoLegend } from './fragments/slices'

const graphQuery = `
{
  pr_tv {
    ${gallery}
    body {
      ${event}
      ${videoLegend}
    }
  }
}`

export default graphQuery
