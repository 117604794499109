import { render, staticRenderFns } from "./UIVideoPlayer.vue?vue&type=template&id=d94aad10&scoped=true&"
import script from "./UIVideoPlayer.vue?vue&type=script&lang=ts&"
export * from "./UIVideoPlayer.vue?vue&type=script&lang=ts&"
import style0 from "./UIVideoPlayer.vue?vue&type=style&index=0&id=d94aad10&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d94aad10",
  null
  
)

export default component.exports