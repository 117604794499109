import { getDocumentCover } from '../utils'
export default `
...on related_content {
non-repeat {
title
}
repeat {
image
title
link {
${getDocumentCover()}
}
}
}`
