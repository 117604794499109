export default `
...on question {
non-repeat {
question
}
repeat {
answer
accuracy
explanation
}
}`