export default `
...on fun_fact {
non-repeat {
title
description
cta
popin_title
fact
has_avatar
}
}`