import { render, staticRenderFns } from "./UIBulletList.vue?vue&type=template&id=2388d89e&scoped=true&"
import script from "./UIBulletList.vue?vue&type=script&lang=ts&"
export * from "./UIBulletList.vue?vue&type=script&lang=ts&"
import style0 from "./UIBulletList.vue?vue&type=style&index=0&id=2388d89e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2388d89e",
  null
  
)

export default component.exports