export default `
...on key_figures {
non-repeat {
title
subtitle
}
repeat {
key_figure_value
key_figure_caption
}
}`