export default `
...on quizz {
non-repeat {
title
description
cta
quizz {
...on quizz {
title
description
cta
body
}
}
}
}`
