// import { getDocumentCover } from '../utils'

export default `
...on project_card {
non-repeat {
title
}
repeat {
description
project {
...on program{
cover_title
}
...on project{
cover_title
related_program
}
}
}
}`
