import { render, staticRenderFns } from "./UIActionBanner.vue?vue&type=template&id=859e157a&scoped=true&"
import script from "./UIActionBanner.vue?vue&type=script&lang=ts&"
export * from "./UIActionBanner.vue?vue&type=script&lang=ts&"
import style0 from "./UIActionBanner.vue?vue&type=style&index=0&id=859e157a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "859e157a",
  null
  
)

export default component.exports