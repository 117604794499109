import { tpQuestion } from './fragments/slices'

const graphQuery = `
{
  transformational_projects {
    body {
      ${tpQuestion}
    }
  }
}`

export default graphQuery
