import { render, staticRenderFns } from "./UICelebrationDialog.vue?vue&type=template&id=5b73c932&scoped=true&"
import script from "./UICelebrationDialog.vue?vue&type=script&lang=ts&"
export * from "./UICelebrationDialog.vue?vue&type=script&lang=ts&"
import style0 from "./UICelebrationDialog.vue?vue&type=style&index=0&id=5b73c932&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b73c932",
  null
  
)

export default component.exports