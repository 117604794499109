const frameToTime = (frame, fps = 30, output = 'second') => {
    let duration = 0
    switch (output) {
      case 'second' :
        duration = frame / fps
        break
    }
    return duration
  }
  
  // curry method to set default framerate
  const setFPS = fps => frame => frameToTime(frame, fps)
  
  export {
    frameToTime as default,
    frameToTime,
    setFPS
  }
  