import { render, staticRenderFns } from "./EventLivePage.vue?vue&type=template&id=51ea506c&scoped=true&"
import script from "./EventLivePage.vue?vue&type=script&lang=ts&"
export * from "./EventLivePage.vue?vue&type=script&lang=ts&"
import style0 from "./EventLivePage.vue?vue&type=style&index=0&id=51ea506c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ea506c",
  null
  
)

export default component.exports