import { render, staticRenderFns } from "./AccessoryCelebration.vue?vue&type=template&id=232a3fb7&scoped=true&"
import script from "./AccessoryCelebration.vue?vue&type=script&lang=ts&"
export * from "./AccessoryCelebration.vue?vue&type=script&lang=ts&"
import style0 from "./AccessoryCelebration.vue?vue&type=style&index=0&id=232a3fb7&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232a3fb7",
  null
  
)

export default component.exports