import menuItems from './fragments/menu-items'

const graphQuery = `
{
  brand_homes_xp {
    title
    ${menuItems}
  }
}`

export default graphQuery
