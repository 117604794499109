var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UIDialog',{staticClass:"ui-drop-dialog",attrs:{"active":_vm.showDialog,"inline":_vm.isMobile}},[_c('transition',{attrs:{"name":"ui-drop-dialog","appear":""}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"card rounded-xl"},[_c('div',{staticClass:"picture rounded"},[_c('img',{attrs:{"src":_vm.spot.drop.image,"alt":""}})]),_c('h2',{staticClass:"title title-h1"},[_vm._v(_vm._s(_vm.spot.drop.title))]),_c('div',{staticClass:"text body",domProps:{"innerHTML":_vm._s(_vm.spot.drop.text)}}),_c('div',{staticClass:"drop-zone rounded",class:[
            _vm.revealClass,
            _vm.animationClass,
            {
              dragging: _vm.draggedObject,
              'accept-drop': _vm.acceptDrop || _vm.droppable,
              'has-drop': _vm.drop
            }
          ],on:{"mouseenter":function($event){_vm.acceptDrop = true},"mouseleave":function($event){_vm.acceptDrop = false},"mouseup":_vm.onMouseUp,"touchend":_vm.onMouseUp}},[_c('transition',{attrs:{"name":"drop-pop"}},[(_vm.drop)?_c('div',{staticClass:"drop rounded"},[_c('picto',{staticClass:"picto",attrs:{"name":_vm.drop}})],1):_vm._e()]),_c('transition',{attrs:{"name":"drop-pop"}},[(_vm.drop)?_c('picto',{staticClass:"icon",attrs:{"name":_vm.type}}):_vm._e()],1)],1)]),_c('btn',{staticClass:"close",attrs:{"icon":"close","shadow":"","large":"","color":"accent"},on:{"click":_vm.close}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }