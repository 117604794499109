export default `
...on encounter {
non-repeat {
label
spot
avatar
avatar_name
avatar_desc
drop_image
drop_title
drop_content
}
repeat {
dialog_content
}
}`