import { render, staticRenderFns } from "./UIVideoPopin.vue?vue&type=template&id=cc0d0618&scoped=true&"
import script from "./UIVideoPopin.vue?vue&type=script&lang=ts&"
export * from "./UIVideoPopin.vue?vue&type=script&lang=ts&"
import style0 from "./UIVideoPopin.vue?vue&type=style&index=0&id=cc0d0618&lang=stylus&scoped=true&"
import style1 from "./UIVideoPopin.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc0d0618",
  null
  
)

export default component.exports