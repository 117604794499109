<!--  https://vuematerial.io -->

<template>
  <UIPortal :mdAttachToParent="attachToParent">
    <transition name="ui-overlay">
      <div class="ui-overlay" :class="[overlayClasses, { noCursor }]" v-on="$listeners" v-if="active"></div>
    </transition>
  </UIPortal>
</template>

<script>
import UIPortal from '@/components/UIPortal'

export default {
  name: 'UIOverlay',

  components: {
    UIPortal
  },

  props: {
    active: Boolean,
    attachToParent: Boolean,
    fixed: Boolean,
    noCursor: Boolean
  },

  computed: {
    overlayClasses() {
      return {
        'ui-fixed': this.fixed
      }
    }
  }
}
</script>

<style lang="stylus">

.ui-overlay
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  z-index 10
  overflow hidden
  background rgba(#004275, .7)
  transition .35s cubic-bezier(.4, 0, .2, 1)
  transition-property opacity
  will-change opacity


  body > &,
  &.ui-fixed
    position fixed


  &.noCursor
    &.ui-overlay-leave-active
      cursor none

  &.behind-notebook
    z-index 119

  &.above-square-ui
    z-index 119

  &.above-notebook
    z-index 121

  &.achievments
    z-index 199


.ui-overlay-enter,
.ui-overlay-leave-active
  opacity 0
</style>
