import { render, staticRenderFns } from "./Iframes.vue?vue&type=template&id=d337ab12&scoped=true&"
import script from "./Iframes.vue?vue&type=script&lang=ts&"
export * from "./Iframes.vue?vue&type=script&lang=ts&"
import style0 from "./Iframes.vue?vue&type=style&index=0&id=d337ab12&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d337ab12",
  null
  
)

export default component.exports