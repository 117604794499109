var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"custom":"","to":_vm.to || 'false'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActiveRoute = ref.isExactActive;
var navigate = ref.navigate;
return [_c('button',{ref:"button",staticClass:"ui-btn",class:[
      {
        cta: !_vm.compact,
        'cta-small': _vm.compact,
        primary: _vm.primary,
        dark: _vm.dark,
        light: _vm.light,
        radio: _vm.radio,
        outlined: _vm.outlined,
        pill: _vm.pill,
        compact: _vm.compact,
        'full-width': _vm.fullWidth,
        large: _vm.large,
        wide: _vm.wide,
        densify: _vm.densify,
        _isdisabled: _vm._isdisabled,
        iconOnly: _vm.iconOnly,
        active: _vm.active || isExactActiveRoute,
        'is-route-active': isExactActiveRoute,
        'elevation-12': _vm.shadow,
        rounded: !_vm.iconOnly || _vm.large,
        'rounded-8 md:rounded-12': _vm.iconOnly && !_vm.large,
        boldActive: _vm.boldActive
      },
      _vm.schemeClass
    ],attrs:{"type":"button"},on:{"click":function($event){return _vm._onClick($event, navigate)}}},[(_vm.icon)?_c('icon',{staticClass:"icon",class:("icon--" + _vm.icon),attrs:{"name":_vm.icon}}):_vm._e(),(_vm.picto)?_c('picto',{staticClass:"picto",class:("picto--" + _vm.picto),attrs:{"name":_vm.picto}}):_vm._e(),_c('span',{ref:"content",staticClass:"btn-content"},[_vm._t("default")],2),(_vm.loading)?_c('span',{staticClass:"loading-overlay"},[_c('UISpinner')],1):_vm._e()],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }