import { encounterGarden as encounter } from './fragments/slices'

const graphQuery = `
{
    the_garden {
        body {
            ${encounter}
        }
    }
}`

export default graphQuery
