import { render, staticRenderFns } from "./UIProfilCardPicto.vue?vue&type=template&id=68aa4d84&scoped=true&"
import script from "./UIProfilCardPicto.vue?vue&type=script&lang=ts&"
export * from "./UIProfilCardPicto.vue?vue&type=script&lang=ts&"
import style0 from "./UIProfilCardPicto.vue?vue&type=style&index=0&id=68aa4d84&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68aa4d84",
  null
  
)

export default component.exports