import { render, staticRenderFns } from "./UITPQuizzResultDialog.vue?vue&type=template&id=6425b084&scoped=true&"
import script from "./UITPQuizzResultDialog.vue?vue&type=script&lang=ts&"
export * from "./UITPQuizzResultDialog.vue?vue&type=script&lang=ts&"
import style0 from "./UITPQuizzResultDialog.vue?vue&type=style&index=0&id=6425b084&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6425b084",
  null
  
)

export default component.exports