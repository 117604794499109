var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-event-card",class:[
    _vm.stateClass,
    ("ui-event-card--" + _vm.variant),
    {
      expanded: _vm.expanded,
      fluid: _vm.fluid,
      noHover: _vm.noHover,
      isSuggested: _vm.isSuggested,
      mandatory: _vm.event.mandatory
    } ],on:{"click":_vm.onClickCard}},[(_vm.variant === _vm.Variants.WRAPPED)?_c('div',{staticClass:"wrapped-title cta-small",domProps:{"innerHTML":_vm._s(_vm.wrappedTitle)}}):_vm._e(),(_vm.hasCover)?_c('figure',{staticClass:"cover rounded-16"},[_c('img',{attrs:{"src":_vm.event.content.coverUrl,"alt":""}})]):_vm._e(),_c('div',{staticClass:"title primary-accent",class:_vm.variant === _vm.Variants.SPOTLIGHT ? 'title-h1' : 'title-h3',domProps:{"innerHTML":_vm._s(_vm.event.title)}}),(_vm.expanded && _vm.event.location)?_c('div',{staticClass:"location body-small"},[_c('icon',{staticClass:"icon",attrs:{"name":"location"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.locationName)}})],1):_vm._e(),_c('div',{staticClass:"date body-small"},[(_vm.isLive)?_c('span',{staticClass:"in-progress-content body-live",domProps:{"innerHTML":_vm._s(_vm.$t('events.card.live'))}}):(_vm.state === 'over')?_c('span',{staticClass:"is-over-content",domProps:{"innerHTML":_vm._s(_vm.$t('events.card.over'))}}):[(_vm.formattedWeekDay)?_c('span',{staticClass:"day",domProps:{"innerHTML":_vm._s(_vm.formattedWeekDay)}}):_vm._e(),(_vm.formattedTime)?_c('span',{staticClass:"time",domProps:{"innerHTML":_vm._s(_vm.formattedTime)}}):_vm._e()]],2),(_vm.expanded)?_c('div',{staticClass:"card-description"},[_c('button',{staticClass:"button-description cta-small",domProps:{"innerHTML":_vm._s(_vm.$t('events.cardButton.description'))},on:{"click":function($event){_vm.openDetailsPopin=true}}}),(_vm.gameLink)?_c('UIEventPlayGameBtn',{attrs:{"link":_vm.gameLink}}):_vm._e()],1):_vm._e(),(_vm.expanded || _vm.wrapped)?_c('div',{staticClass:"card-cta"},[_c('UIEventButton',{attrs:{"event":_vm.event,"densify":""},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1):_vm._e(),_c('UIEventCardDetailedDialog',{attrs:{"event":_vm.event,"active":_vm.openDetailsPopin},on:{"update:active":function($event){_vm.openDetailsPopin=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }