var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"ui-hotspot",class:[
    {
      'is-light': _vm.light,
      'is-live': _vm.live,
      'is-checked': _vm.checked,
      _isactive: !_vm.disabled,
      _iscard: _vm.card,
      _islocked: _vm.locked,
      _ishovered: _vm.hovered || _vm.mouseOver
    },
    _vm.schemeClass
  ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm._onClick.apply(null, arguments)},"mouseenter":_vm._onMouseEnter,"mouseleave":_vm._onMouseLeave}},[_c('div',{ref:"spot",staticClass:"spot",class:[_vm.card ? 'elevation-12' : 'elevation-24']},[_c('div',{staticClass:"spot-outer",class:[_vm.card ? '' : 'elevation-16']},[_c('div',{ref:"spotInner",staticClass:"spot-inner"})]),(_vm.iconOrLock)?_c('div',{staticClass:"spot-icon"},[_c('transition',{attrs:{"name":"hotspot-icon","mode":"out-in"}},[_c('span',{key:_vm.locked,ref:"icon",staticClass:"icon-wrapper"},[_c('picto',{staticClass:"icon",attrs:{"name":_vm.iconOrLock,"draggable":"false"}})],1)])],1):_vm._e(),(_vm.arrow)?_c('span',{ref:"arrow",staticClass:"arrow"}):_vm._e(),(_vm.live)?_c('span',{staticClass:"spot-notif"},[_c('icon',{staticClass:"icon",attrs:{"name":"play-simple"}})],1):_vm._e(),(_vm.checked)?_c('span',{staticClass:"spot-notif"},[_c('icon',{staticClass:"icon",attrs:{"name":"check"}})],1):_vm._e()]),(_vm.live)?_c('span',{staticClass:"live rounded-8 body-live"},[_vm._v(_vm._s(_vm.$t('hotspot.live')))]):_vm._e(),_c('div',{staticClass:"spot-label"},[(_vm.label)?[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.labelVisible),expression:"labelVisible"}],ref:"label",staticClass:"label cta rounded-16 elevation-16"},[_c('span',{ref:"labelText",staticClass:"label-text"},[_vm._v(_vm._s(_vm.label))]),_c('span',{ref:"labelTextHover",staticClass:"label-text-hover"},[_vm._v(_vm._s(_vm.label))])])])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }