export default `
...on content_video {
non-repeat {
title
}
repeat {
video
image
title
}
}`