import bar from './bar'
import brandCategory from './brand_category'
import brandHomes from './brand_homes'
import brandHomesXP from './brand_homes_xp'
import brand from './brand'
import businessCenter from './business_center'
import employeeExperience from './employee_experience'
import learningForTheFuture from './learning_for_the_future'
import marquee from './marquee'
import modules from './modules'
import onTrade from './on_trade'
import prTv from './pr_tv'
import program from './program'
import project from './project'
import quizz from './quizz'
import spot from './spot'
import testEvalution from './test_evaluation'
import theGarden from './the_garden'
import theTrail from './the_trail'
import touristOffice from './tourist_office'
import transformationalProjects from './transformational_projects'

export {
  bar,
  brandCategory,
  brandHomes,
  brandHomesXP,
  brand,
  businessCenter,
  employeeExperience,
  learningForTheFuture,
  marquee,
  modules,
  onTrade,
  prTv,
  program,
  project,
  quizz,
  spot,
  testEvalution,
  theGarden,
  theTrail,
  touristOffice,
  transformationalProjects
}

export const graphQueries = {
  brand_category: brandCategory,
  brand_homes: brandHomes,
  brand_homes_xp: brandHomesXP,
  brand: brand,
  employee_experience: employeeExperience,
  learning_for_the_future: learningForTheFuture,
  modules,
  on_trade: onTrade,
  pr_tv: prTv,
  program,
  project,
  spot,
  the_garden: theGarden,
  the_trail: theTrail,
  transformational_projects: transformationalProjects
}
