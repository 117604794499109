import menuItems from './fragments/menu-items'
import playlist from './fragments/playlist'

const graphQuery = `
{
    bar {
        title
        uid
        ${playlist}
        ${menuItems}
    }
}`

export default graphQuery
