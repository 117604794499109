import { getDocumentCover } from './utils'

export default `
menu_items {
item {
${getDocumentCover()}
...on bar {
title
}
}
label
}`