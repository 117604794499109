import cover from './fragments/cover'
import projecID from './fragments/project-id'

import {
    title,
    text,
    bulletList,
    downloadAssets,
    links,
    keyFigures,
    category,
    relatedContent,
    photoLegend,
    photo,
    videoLegend,
    video,
    carousel,
    contentVideo,
    funFact,
    funFactAlt,
    quizz,
    projectID as projectIDSlice,
    projectCard,
    expert
  } from './fragments/slices'

const graphQuery = `
{
    project {
        ${cover}
        ${projecID}
        body {
            ${title}
            ${text}
            ${bulletList}
            ${downloadAssets}
            ${links}
            ${keyFigures}
            ${category}
            ${relatedContent}
            ${photoLegend}
            ${photo}
            ${videoLegend}
            ${video}
            ${carousel}
            ${contentVideo}
            ${funFact}
            ${funFactAlt}
            ${quizz}
            ${projectIDSlice}
            ${projectCard}
            ${expert}
        }
    }
}`

export default graphQuery
