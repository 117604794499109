import { render, staticRenderFns } from "./UITPQuizzWheelQuestion.vue?vue&type=template&id=1754642c&scoped=true&"
import script from "./UITPQuizzWheelQuestion.vue?vue&type=script&lang=ts&"
export * from "./UITPQuizzWheelQuestion.vue?vue&type=script&lang=ts&"
import style0 from "./UITPQuizzWheelQuestion.vue?vue&type=style&index=0&id=1754642c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1754642c",
  null
  
)

export default component.exports