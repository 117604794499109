import { event, videoLegend } from './fragments/slices'

const graphQuery = `
{
  tourist_office {
    body {
      ${event}
      ${videoLegend}
    }
  }
}`

export default graphQuery
