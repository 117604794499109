<template>
    <div class="ab-section">

      <component 
        v-for="selectId in section.selects" 
        :key="selectId"
        v-bind:is="getComponent(selectId)"
        :select="getSelect(selectId)"
        :currentConfig=currentConfig
      />

    </div>
</template>




<script>

import ABSwatch from './ABSwatch.vue';
import ABOptions from './ABOptions.vue';
import avatarConfigurator from '@/store/modules/avatarConfigurator';

export default {

    components: {
        ABSwatch,
        ABOptions,
    },

    props: {
        section: {
            type:Object,
            required: true,
        },

        currentConfig: {
            type:Object,
            required: true,
        },

    },

    methods: {

      getComponent( id ) {
        const type = this.getSelect( id ).type
        switch (type) {
          case "partial": return "ABOptions"
          case "style": return "ABSwatch"
          default: throw `invalid type ${type}`
        }
      },

      getSelect( id ) {
        return avatarConfigurator.getSelectById( id )
      },
    },

};
</script>





<style lang="stylus" scoped>

.ab-section {
    text-align center
    padding 0
    display flex
    flex-direction column
    padding-bottom rem(24)

    +mobile() {
      flex-direction row
      align-items center
      padding 0 rem(16) rem(16)
    }
}

</style>