import { encounterTrail as encounter } from './fragments/slices'

const introduction = `
intro_image
intro_title
intro_content
intro_cta
`

const hotspots = `
body {
    ${encounter}
}
`

const graphQuery = `
{
    the_trail {
        ${introduction}
        ${hotspots}
    }
}`

export default graphQuery
