import { render, staticRenderFns } from "./UIEventCardDetailed.vue?vue&type=template&id=26772e55&scoped=true&"
import script from "./UIEventCardDetailed.vue?vue&type=script&lang=ts&"
export * from "./UIEventCardDetailed.vue?vue&type=script&lang=ts&"
import style0 from "./UIEventCardDetailed.vue?vue&type=style&index=0&id=26772e55&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26772e55",
  null
  
)

export default component.exports